import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import ErrorBoundary from '../../../components/ErrorBoundary';
import CheckoutPage from '../../../pages/Checkout';
import ConfirmationPage from '../../../pages/Confirmation';
import Error from '../../../pages/Error';

export const StripeRouter = () => {
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  return (
    <Routes>
      <Route element={<ErrorWrapper />}>
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="*" element={<Navigate to="/checkout" replace />} />
      </Route>
    </Routes>
  );
};
