import React from 'react';

import { CircularSpinner } from '@aster/client/ui/CircularSpinner/CircularSpinner';
import { SUBSCRIBED_STATUSES } from '@aster/shared/dtos/stripe';

import { useSubscriptionInformationQuery } from '../queries/use-subscription-information';
import { StripeRouter } from '../routers/StripeRouter';

import { useAuth } from '../../../authentication/AuthProvider';

export const StripeProvider = ({ children }: { children: React.ReactNode }) => {
  const { profile } = useAuth();

  const { subscriptionInformation, isLoading } =
    useSubscriptionInformationQuery(profile?.practiceId, 'provider');

  if (isLoading) {
    return (
      <div className="w-screen h-screen content-center text-center">
        <CircularSpinner size="3x" />
      </div>
    );
  }

  // For any non-active subscription status, we'll block all
  // navigation and have the user go into the Stripe Router,
  // which exists fully outside the rest of the application.
  //
  // This way we don't risk loading any application-specific
  // code into the browser, and exposing features other than
  // the checkout.
  if (
    !SUBSCRIBED_STATUSES.includes(
      subscriptionInformation?.stripeSubscriptionStatus ?? ''
    )
  ) {
    return <StripeRouter />;
  }

  return children;
};
