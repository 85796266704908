import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useResizeDetector } from 'react-resize-detector';

import { ViewerProps } from './types';

export const PDFViewer = ({
  url,
  scale,
  onDocumentLoadSuccess,
}: ViewerProps) => {
  const { width, ref } = useResizeDetector();

  const [numPages, setNumPages] = useState(0);

  return (
    <div ref={ref} className="w-full">
      <Document
        className="h-full w-full"
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
          onDocumentLoadSuccess();
        }}
        file={url}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            width={width}
            scale={scale}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );
};
