import {
  LabTestDTO,
  TryVitalCreateOrderAOEAnswerDTO,
  TryVitalMarkerQuestionDTO,
} from '@aster/shared/dtos/labs';
import { StateCreator } from 'zustand';

type CreateLabsOrderState = {
  selectedLabTest: LabTestDTO | null;
  searchQuery: string;
  answers: Map<string, TryVitalCreateOrderAOEAnswerDTO>;
  newOrderIDs: string[];
  isCheckoutQuestionsVisible: boolean;
};

type CreateLabsOrderAction = {
  selectLabTest: (labTest: LabTestDTO | null) => void;
  unselectLabTest: () => void;
  initializeAsnwers: (questions: TryVitalMarkerQuestionDTO[]) => void;
  changeAnswer: (answer: string, questionId: number, markerId: number) => void;
  addNewOrderID: (orderID: string) => void;
  search: (query: string) => void;
  showCheckoutQuestions: () => void;
};
export type CreateLabsSlice = CreateLabsOrderState & CreateLabsOrderAction;

export const createCreateLabsSlice: StateCreator<CreateLabsSlice> = (set) => ({
  // State
  selectedLabTest: null,
  searchQuery: '',
  answers: new Map(),
  newOrderIDs: [],
  isCheckoutQuestionsVisible: false,
  // Actions
  selectLabTest: (labTest) => {
    set({ selectedLabTest: labTest });
  },
  unselectLabTest: () => {
    set({
      selectedLabTest: null,
      isCheckoutQuestionsVisible: false,
      answers: new Map(),
    });
  },
  changeAnswer: (answer, questionId, markerId) => {
    set((state) => {
      const answers = new Map(state.answers);
      if (answers.has(`${questionId}-${markerId}`)) {
        answers.set(`${questionId}-${markerId}`, {
          ...(answers.get(
            `${questionId}-${markerId}`
          ) as TryVitalCreateOrderAOEAnswerDTO),
          answer,
        });
      }

      return { answers };
    });
  },
  addNewOrderID: (orderID) => {
    set((state) => {
      if (!orderID) return state;
      const newOrderIDs = [...state.newOrderIDs, orderID];
      return { newOrderIDs };
    });
  },
  search: (query) => {
    set({ searchQuery: query });
  },
  showCheckoutQuestions: () => {
    set({ isCheckoutQuestionsVisible: true });
  },
  initializeAsnwers: (questions) => {
    set({
      answers: new Map(
        questions.map((question) => [
          `${question.id}-${question.markerId}`,
          {
            question_id: question.id,
            marker_id: question.markerId,
            answer: '',
          },
        ])
      ),
    });
  },
});
