import { PricingTable } from '../../integrations/stripe/components/PricingTable';
import { useAuth } from '../../authentication/AuthProvider';

const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;

export const Checkout = () => {
  const { profile } = useAuth();

  return (
    <div className="w-full h-screen text-center content-center">
      <PricingTable
        pricingTableId={pricingTableId}
        publishableKey={publishableKey}
        clientReferenceId={profile?.practiceId}
      />
    </div>
  );
};
