import axios from '../../../app/axiosConfig';

export const deleteFiles = async (ids: string[]) =>
  axios.delete(`/v2/documents`, {
    data: ids,
  });

export const fetchPracticeFiles = async () => {
  const res = await axios.get('/v2/documents');
  return res.data;
};

export const fetchPatientFiles = async (id: string) => {
  const res = await axios.get(`/v2/documents/${id}`);
  return res.data;
};
