export const SubscriptionStatus = {
  active: 'active',
  inactive: 'inactive',
};

// In the future, we may want to support more in-between states,
// which exist between an unsubscribed user and the subscription
// activation.
export const LOADABLE_SUBSCRIPTION_STATUSES = [SubscriptionStatus.inactive];

// In the future, we may want to support more custom subscription
// states, which may vary depending on the end choice for product
// and offerings.
export const SUBSCRIBED_STATUSES = [SubscriptionStatus.active];
