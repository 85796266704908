import { firebaseConfig } from './firebase';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import Router from '../Router';

import { AuthProvider } from '../authentication/AuthProvider';
import { DosespotProvider } from '../features/dosespot/DosespotProvider';
import { FrontChatProvider } from '../integrations/front/providers/FrontChatProvider';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { StripeProvider } from '../integrations/stripe/providers/StripeProvider';

function App() {
  const app = initializeApp(firebaseConfig);

  getAnalytics(app);

  return (
    <div className="flex h-screen w-full">
      <AuthProvider>
        <StripeProvider>
          <FrontChatProvider>
            <DosespotProvider>
              <Sidebar />
              <div className="flex flex-col w-full">
                <Header />
                <main className="w-full flex flex-col h-screen overflow-auto">
                  <Router />
                </main>
              </div>
            </DosespotProvider>
          </FrontChatProvider>
        </StripeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
