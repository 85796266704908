import axios from '../../../app/axiosConfig';
import { LabsOrderResultsProcessingStatusDTO } from '@aster/shared/dtos/labs';
import { useQuery } from '@tanstack/react-query';

const fetchLabOrderResultProcessingStatus = async (
  labOrderID: string,
  jobID: string
) => {
  const response = await axios.get<LabsOrderResultsProcessingStatusDTO>(
    `/v2/labs/orders/${labOrderID}/processing-status/${jobID}`
  );
  return response.data;
};
export function usePollResultsPDFProcessingStatus({
  labOrderID,
  jobID,
}: {
  jobID: string | undefined;
  labOrderID: string | undefined;
}) {
  const { data: jobStatus, isLoading: isPolling } = useQuery({
    queryKey: ['results-pdf-job-status', labOrderID, jobID],
    queryFn: () =>
      fetchLabOrderResultProcessingStatus(
        labOrderID as string,
        jobID as string
      ),
    refetchInterval: (query) =>
      query.state.data?.status === 'completed' ||
      query.state.data?.status === 'failed'
        ? false
        : 2000,
    refetchIntervalInBackground: true,
    enabled: labOrderID != undefined && jobID != undefined,
  });

  return { jobStatus, isPolling };
}
