import { useState } from 'react';

import {
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Button } from '@aster/client/ui/Button/Button';
import { cn } from '@aster/client/utils';

import { ImageViewer } from './viewers/ImageViewer';
import { PDFViewer } from './viewers/PDFViewer';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export type DocumentViewerProps = {
  url: string;
  fileName: string;
  open?: boolean;
  handleClose?: () => void;
  handleDownload?: () => void;
};

const SCALE_FACTOR = 0.1;

export const DocumentViewer = ({
  url,
  fileName,
  open,
  handleClose,
  handleDownload,
}: DocumentViewerProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [scale, setScale] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const filetype = fileName.split('.').pop();

  let Viewer;

  switch (filetype) {
    case 'pdf':
      Viewer = PDFViewer;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
      Viewer = ImageViewer;
      break;
  }

  if (!open || !Viewer) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      classes={{ paper: 'h-full' }}
      fullWidth
    >
      <DialogTitle className="flex gap-6 items-center">
        {fileName}{' '}
        <Button variant="outline" size="sm" onClick={handleDownload}>
          <FontAwesomeIcon icon={faDownload} />
          Download
        </Button>
      </DialogTitle>
      <DialogContent className="p-0 mx-6 mb-5 text-center relative h-full">
        <div
          className={cn(
            'w-full h-full flex justify-center items-center overflow-hidden',
            {
              hidden: !isLoading,
            }
          )}
        >
          <CircularProgress />
        </div>
        <div
          className={cn({
            hidden: isLoading,
          })}
        >
          <Viewer
            url={url}
            scale={scale}
            metadata={{ url, fileName }}
            onDocumentLoadSuccess={() => setIsLoading(false)}
          />
        </div>
        {!isLoading && (
          <div className="sticky bottom-0 z-30">
            <div className="absolute bottom-0 left-0 w-full text-center p-3 flex justify-center items-center z-30">
              <Button
                variant="outline"
                className="rounded-r-none"
                onClick={() => setScale((scale) => scale - SCALE_FACTOR)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
              </Button>
              <span className="h-[42px] inline-flex justify-center items-center py-3 px-5 border-y border-gray-300 bg-white text-aster-primary font-semibold transition-colors">
                {Number((scale * 100).toFixed(0))}%
              </span>
              <Button
                variant="outline"
                className="rounded-l-none"
                onClick={() => setScale((scale) => scale + SCALE_FACTOR)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
