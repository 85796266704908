import { useQuery } from '@tanstack/react-query';

import { SubscriptionDTO } from '@aster/shared/dtos/practice';
import { LOADABLE_SUBSCRIPTION_STATUSES } from '@aster/shared/dtos/stripe';

import axios from '../../../app/axiosConfig';

const getSubscriptionInformation = async (id: string) => {
  const {
    data: { stripeCustomerID, stripeSubscriptionStatus },
  } = await axios.get<SubscriptionDTO>(`/v2/stripe/subscription-information`);

  return {
    stripeCustomerID,
    stripeSubscriptionStatus,
  };
};

export const useSubscriptionInformationQuery = (
  id?: string,
  scope?: string,
  opts = {
    enableRefetch: false,
  }
) => {
  const { data: subscriptionInformation, ...rest } = useQuery({
    queryKey: ['subscriptionInformation', ...(scope ? [scope] : [])],
    queryFn: () => getSubscriptionInformation(id as string),
    enabled: !!id,
    refetchInterval: (query: any) =>
      !opts.enableRefetch ||
      LOADABLE_SUBSCRIPTION_STATUSES.includes(
        query.data?.stripeSubscriptionStatus ?? ''
      )
        ? false
        : 1000,
  });

  return { subscriptionInformation, ...rest };
};
