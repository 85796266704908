import { ViewerProps } from './types';

export const ImageViewer = ({
  url,
  scale,
  metadata,
  onDocumentLoadSuccess,
}: ViewerProps) => {
  return (
    <div className="overflow-hidden flex items-center justify-center pointer-events-auto">
      <div className="w-full h-full overflow-scroll">
        <img
          src={url}
          alt={metadata?.fileName}
          onLoad={onDocumentLoadSuccess}
          style={{ transform: `scale(${scale})` }}
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};
