import Typography from '../../../components/Typography';
import { names } from '@aster/shared/utils/names';
import { TryVitalMarkerQuestionDTO } from '@aster/shared/dtos/labs';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import BasicTextfield from '../../../components/Textfield';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { colors } from '../../../theme';

export default function LabTestMarkerQuestion({
  question,
  handleAnswerChange,
}: {
  question: TryVitalMarkerQuestionDTO;
  handleAnswerChange: (
    answer: string,
    questionId: number,
    markerId: number
  ) => void;
}) {
  return (
    <Card
      elevation={0}
      className="w-full h-fit rounded-[0.625rem] border border-grayBackground"
    >
      <CardContent className="flex flex-col">
        <Typography
          text={names(question.value).sentenceCase}
          variant="bodyMedium"
          customClass="font-semibold text-black overflow-ellipsis max-w-[80%] overflow-hidden whitespace-nowrap"
        />
        <Typography
          text={`Marker: ${question.markerName}`}
          variant="bodySmall"
          customClass="mb-4"
        />
        {question.type === 'numeric' && (
          <BasicTextfield
            label="Answer"
            name="answer"
            containerClasses="w-60"
            variant="filled"
            type="number"
            onChange={(e) =>
              handleAnswerChange(
                e.currentTarget.value,
                question.id,
                question.markerId
              )
            }
          />
        )}
        {question.type === 'text' && (
          <BasicTextfield
            label="Answer"
            name="answer"
            containerClasses="w-60"
            variant="filled"
            onChange={(e) =>
              handleAnswerChange(
                e.currentTarget.value,
                question.id,
                question.markerId
              )
            }
          />
        )}
        {question.type === 'choice' && (
          <RadioGroup
            aria-label="Answer"
            name="Answer"
            onChange={(e) =>
              handleAnswerChange(
                e.currentTarget.value,
                question.id,
                question.markerId
              )
            }
          >
            {question.answers.map((choice) => (
              <FormControlLabel
                key={choice.id}
                value={choice.code}
                classes={{
                  label: 'text-bodySmall',
                }}
                control={
                  <Radio
                    sx={{
                      color: colors.gray,
                      '&.Mui-checked': {
                        color: colors.green,
                      },
                    }}
                  />
                }
                label={choice.value}
              />
            ))}
          </RadioGroup>
        )}
      </CardContent>
    </Card>
  );
}
