import { EncounterDTO } from '@aster/shared/dtos/encounter';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchNote = async (encounterID: string) => {
  const response = await axios.get<EncounterDTO>(`encounters/${encounterID}`);
  return response.data;
};

export function useEncounterDetailQuery(encounterID: string) {
  const { data: encounterData, isLoading: isEncounterLoading } = useQuery({
    queryKey: ['encounters', encounterID],
    queryFn: () => fetchNote(encounterID),
  });

  return { encounterData, isEncounterLoading };
}
