import { useEffect } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export type PricingTableProps = {
  pricingTableId: string;
  publishableKey: string;
  clientReferenceId?: string;
};

export const PricingTable = ({
  pricingTableId,
  publishableKey,
  clientReferenceId,
}: PricingTableProps) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      client-reference-id={clientReferenceId}
    ></stripe-pricing-table>
  );
};
